import { Routes } from '@angular/router';
import { canActivatePowerUser } from './guards/is-power-user.guard';

export const appRoutes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('@agdir/heimdall').then((m) => m.AUTH_ROUTES),
	},
	{
		path: '',
		canActivate: [canActivatePowerUser],
		canActivateChild: [canActivatePowerUser],
		loadComponent: () => import('./pages/powertools-page.component').then((m) => m.PowertoolsPageComponent),
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'dashboard',
			},
			{
				path: 'dashboard',
				loadComponent: () => import('./pages/powertools-dashboard-page.component').then((m) => m.PowertoolsDashboardPageComponent),
				resolve: { title: () => 'Dashboard' },
			},
			{
				path: 'inventory',
				loadChildren: () => import('./pages/inventory-page/inventory-page.routes').then((m) => m.InventoryPageRoutes),
				resolve: { title: () => 'Inventory' },
			},
			{
				path: 'companies',
				loadComponent: () => import('./pages/companies-page/companies-page.component').then((m) => m.CompaniesPageComponent),
				resolve: { title: () => 'Companies' },
			},
			{
				path: 'help-management',
				loadComponent: () => import('./pages/help-management-page/help-management-page.component').then((m) => m.HelpManagementPageComponent),
				resolve: { title: () => 'Help Management' },
			},
			{
				path: 'classifiers',
				loadChildren: () => import('./pages/classifiers-page/classifiers-page.routes').then((m) => m.ClassifiersPageRoutes),
				resolve: { title: () => 'Classifiers' },
			},
			{
				path: 'chart-groups',
				loadChildren: () => import('./pages/chart-groups-page/chart-groups-page.module').then((m) => m.ChartGroupsPageModule),
				resolve: { title: () => 'Chart groups' },
			},
			{
				path: 'push-notification',
				loadComponent: () => import('./pages/push-notification/push-notification.component').then((m) => m.PushNotificationComponent),
				resolve: { title: () => 'Push Notification' },
			},
			{
				path: 'feature-toggle',
				loadComponent: () => import('./pages/feature-toggle/feature-toggle.component').then((m) => m.FeatureToggleComponent),
				resolve: { title: () => 'Feature Toggle' },
			},
			{
				path: 'coming-soon',
				loadComponent: () => import('./pages/coming-soon/coming-soon.component').then((m) => m.ComingSoonPageComponent),
				resolve: { title: () => 'Coming Soon' },
			},
			{
				path: 'i18n',
				loadChildren: () => import('./pages/i18n-page/i18n-page.routes').then((m) => m.I18nPageRoutes),
				resolve: { title: () => 'Internationalization' },
			},
			{
				path: 'create-notification',
				loadComponent: () => import('./pages/create-notification/create-notification.component').then((m) => m.CreateNotificationComponent),
				resolve: { title: () => 'Send notification to a customer' },
			},
		],
	},
];
