import { AgdirCompany } from '@agdir/domain';
import { AuthService, CUSTOMER_PATHS } from '@agdir/services';
import { Injectable, inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
class PermissionsService {
	private auth = inject(AuthService);
	private router = inject(Router);

	canActivate(): Observable<boolean> {
		return this.auth.getCurrentCustomerSession().pipe(
			switchMap((s) => {
				const token = s.cognitoUser?.getSignInUserSession()?.getIdToken().decodePayload();

				if (s.cognitoUser) {
					return of(!!(token && token['cognito:groups']?.includes(AgdirCompany.cognitoAdminGroupName)));
				}
				return this.router.navigateByUrl(CUSTOMER_PATHS.AUTH_SIGNIN);
			}),
		);
	}
}

export const canActivatePowerUser: CanActivateFn | CanActivateChildFn = () => inject(PermissionsService).canActivate();
